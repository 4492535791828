import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container , Row , Col } from 'reactstrap'
import { graphql } from "gatsby"
import Img from "gatsby-image"


export default class HostingPage extends React.Component {
  render() {
    const data = this.props.data
        const pagebghosting = data.pagebghosting.childImageSharp.fluid


return (
  <Layout pageTitle="Alan Adı Barındırma - Hosting">
    <SEO title="Alan Adı Barındırma - Hosting" keywords={[`istanbul Web Tasarım`, `Web Tasarım`, `E-Ticaret`]} description="İstanbul Web Tasarım YS belgeli olarak Alan adı Barındırma Hosting VDS kiralama hizmeti sunar" />

    <section className="bginpage">
    <Img fluid={pagebghosting}
    style={{
                   position: 'absolute',
                   left: 0,
                   top: 0,
                   width: '100%',
                   height: '100%',
                 }} alt="Alan Adı Barındırma"/>
    <Container>
      <Row>
        <Col md="5">
        <div className="bginpagedark">
        <h1 className="mb20  section-title">
        Alan Adı Barındırma ( Hosting )
             </h1>
             <p>%99 uptime sunucular ile kesintisiz alan adı barındırma hizmetimiz ile tanışmaya davetlisiniz.Limitsiz kapasiteli hosting(Alan Adı Barındırma) çözümlerimiz ile her türlü yazılım ihtiyacınıza yönelik sunucu gereksinimleri yapılmış veri barındırma hizmetimiz ile sizlerleyiz.İstanbul Web Tasarım Alan adı Barındırma Hizmetini YS belgeli olarak kullanıcılarına sunar.</p>
  </div>  </Col>
        </Row>
        </Container>
  </section>
  <section className="maincontent">
  <Container>

    <Row>
      <Col md="12" className="acik">
      <h1 className="font-size-normal  section-title">
               <small>Web Tasarım &amp; E-Ticaret &amp; Arama Motoru Optimizasyonu &amp; Kurumsal e-Posta</small>
               Alan Adı Barındırma ( Hosting )
                <div className="bar"></div>
           </h1>
  <p>
    Hosting nedir ?
  Hosting kavramı hakkında detaylı bilgilere yer vermek yerine kısa ve en açıklayıcı sonuçları yazmak müşterilerimiz için çok daha iyi olacaktır.Hosting aslında sizlerin internet dünyasında belirli bir zaman periyodu için kiraladığınız ve sürekli erişelebilir olan hardiskleriniz olarak görebilirsiniz.
  Bu konuda hosting domain ( domain sekmesinde gerekli bilgiler bulunmaktadır ) lerinizin yani alan adlarınızın bir hardiske yönlendirilmesi ve bu hardiskin internet ile sürekli erişim içinde kalması ile web sitelerinizin dosyalarına 7 24 erişilebilir kılmaya yaramaktadır.İsterseniz bir örnek ile açıklayalım.
  www.istanbulwebtasarim.com.tr bizlerin alan adıdır ve bu alan adının yönlenmiş olduğu Control telekominikasyon ve bilişim hizmetlerinin hosting hizmeti sayesinde sizler gece saat 3 te bile olsa bu alan adını browserınıza yazarak sitemizin temel kodlarının ekrana yansımasını görebiliriyorsunuz.
  Hostng seçiminde önemli olan hususlar nelerdir?
  Her sistemin bir alt yapıya ihtiyacı vardır modern ve teknik bir alt yapı ile sisteminiz çok daha iyi olacaktır.Örnek verecek olursak bilgisayarınızın rami hardiski ekran kartı sizlerin donanımsal bilgilerinizdir ve bu donanımın eksiksiz çalışması için bir arayüz kullanımına ihtiyacı vardır buda Windows 7 – xp gibi sürümlerle mevcut olmaktadır.İşte bu noktada yapısal anlamıyla bilgisayarlardan biraz daha gelişmiş olan hosting makinaları ( sunucuları ) temelde işlevsel olarak donanım olarak güçlendirilmiş ve kişinin kullandığı yazılım diline göre en iyi şekilde yorumlanabilip ona göre geliştirilmiş olmalıdır.Bu noktada php tabanlı çalışmak isteyen bir kişi zend teknoloji öekirdeğini iyi yorumlayabilen ve sistem gereksinimlerini kodlamaları daha iyi anlayabilen Linux hostingi tercih etmelidir.Fakat arayüz tasarımı olarak asp.net yada asp gibi kodlama dillerini tercih eden bir kişi ise Windows kurulu sunucularda çalışması daha doğru olacaktır.Bu doğruluk sizin web sitenizden alacağınız verimi ( hız kalite ,kodlama yorumlama ) doğrudan doğruya etkileyecektir.Seçim olarak neye karar verirseniz verin sistem yapınıza göre internet korsanlarının varlığı unutulmayarak mükemmel bir hizmet hız gelen internet bağlantısı gibi seçenekler sizlerin ne yazık ki doğrudan etkiniz olan kavramlar olmayacaktır.Bu noktada sunucularına internet bağlantısına güvenen bir data center ( sunucular ile dolu oda ) ile çalışmanız bu noktada size çok büyük bir avantaj sağlayacaktır.İstanbulwebtasarim hizmetleri Hosting hzmetini doğrudan Control Bilişimden almaktadır ve bayisi niteliğindedir.
  Ne tür Hosting Seçmeliyim?

  Günümüzde hazır tabanlı sistemler göz önüne alındığında hosting hizmetlerinide bu yönde geliştirmeye devam etmekte olan datacenterlar,sizlere sisteminiz için en uygun hosting paketlerini seçmeye davet ediyor.Bu noktada sayfamız da yer verdiğimiz fiyat ve nedenleri okuyabilirsiniz.

  Joomla hosting
  Wordpress hosting
  Seo hosting
  Opencart hosting
  Proticaret hosting</p>
    </Col>
    </Row>
    </Container>
    </section>
  </Layout>
)

}
}
export const pageQuery = graphql`
query {
pagebghosting: file(
 relativePath: { regex: "/bg8.jpg/" }
) {
childImageSharp {
           fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
}
`
